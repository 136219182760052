import WrapPage from '../components/WrapPage';
export default WrapPage;

export async function getServerSideProps(context) {
  if (
    context &&
    context.query &&
    Object.keys(context.query).includes('_escaped_fragment_') &&
    context.query._escaped_fragment_.length > 1
  ) {
    context.res.statusCode = 302;
    context.res.setHeader('Location', `/`);
    return {
      notFound: true,
    };
  }
  const UA = context.req.headers['user-agent'];
  const isMobile = Boolean(
    UA.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );
  const id_ref = context.params.sort_url;
  try {
    const res = await fetch(
      `${process.env.API_SERVER_URL_LOCAL}/app/post/page-detail/${id_ref}?limit=6&limit_cat=${isMobile ? '4' : '9'}`,
      {
        headers: {
          'Content-Type': 'application/json',
          language: context.locale,
        },
      },
    );
    const data = await res.json();
    if (!data || data.code !== 200) {
      return {
        notFound: true,
      };
    }

    if (data?.data?.notFound) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        ...(data.data || {}),
        webViewMobile: context && context.query && context.query.viewapp ? true : false
      },
    };
  } catch (error) {
    console.log(error);
    return {
      props: {
        id_ref: id_ref,
      },
    };
  }
}
